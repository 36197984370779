<template>
  <div class="">
    <navbar title="留言"></navbar>
    <div class="msg-box">
      <!-- 输入框 -->
      <van-field
        class="msg-field"
        v-model="fieldValue"
        rows="7"
        type="textarea"
        placeholder="请输入留言"
        maxlength="50"
        show-word-limit
        @input="fieldInput"
      />
      <!-- 提交按钮 -->
      <div class="commit-btn" @click="commit()">提交</div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import tabbar from "@/components/tabBar.vue";
import navbar from "@/components/navBar.vue";
import { leaveComments } from "@/api/api.js";
import { Toast } from "vant";

export default {
  name: "Home",
  components: {
    tabbar,
    navbar,
  },
  data() {
    return {
      fieldValue: "",
    };
  },
  mounted(){
    
  },
  methods: {
    // 输入框输入值后
    fieldInput() {
      console.log(this.fieldValue);
    },
    commit() {
      if(this.fieldValue == ""){
        Toast('请输入留言')
        return
      }
      if(localStorage.getItem("nickname")==undefined || localStorage.getItem("nickname")==null
      || localStorage.getItem("headImgUrl")==undefined || localStorage.getItem("headImgUrl")==null
      || localStorage.getItem("openId")==undefined || localStorage.getItem("openId")==null || localStorage.getItem("openId")=="undefined"){
        Toast('未获取到微信头像或昵称，请重新登录')
        return
      }
      let obj = {
        content: this.fieldValue,
        contentTime: "",
        deviceCode: localStorage.getItem("deviceCode"),
        openId: localStorage.getItem("openId"),
        tenantCode: "",
        updateTime: "",
        wxAvatarUrl: localStorage.getItem("headImgUrl"),
        wxName: localStorage.getItem("nickname"),
      };
      leaveComments(obj).then((res) => {
        console.log(res)
        if(res.data.code == 44){
          Toast(res.data.msg)
        }else{
          Toast('留言成功！')
          setTimeout(this.$router.push({path:'/'}), 1000);
        }
      });
    },
  },
};
</script>
<style scoped>
.msg-field {
  margin: 0 auto;
  margin-top: 60px;
  box-shadow: 0 0 5px 5px rgb(240, 220, 220);
  width: 92%;
  border-radius: 20px;
}

.commit-btn {
  text-align: center;
  margin: 0 auto;
  margin-top: 60px;
  color: #fff;
  background: #cca766;
  width: 92%;
  height: 80px;
  line-height: 80px;
  font-size: 28px;
  font-family: PingFang SC;
  border-radius: 20px;
}
</style>